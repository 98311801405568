.swal2-container .swal2-title {
    font-size: 14px !important;
}
.swal2-container textarea {
    font-size: 13px !important;
}
.truncate {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }