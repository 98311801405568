.create-vendor .invalid-feedback {
  display: block !important;
}
.custom-error-val .invalid-feedback {
  display: block !important;
}
.toast-top-right {
  // background-color: white !important;
}
.mytoggle input[switch] + label {
  width: 82px;
}
.mytoggle input[switch]:checked + label:after {
  left: 59px;
}
.dashboard-box .card-body {
  padding: var(--bs-card-spacer-y) 14px;
}
.dashboard-box .card {
  margin-bottom: 0px !important;
}
.dashboard-table table td {
  padding: 6px 0px;
}
.search-box .form-control {
  border-radius: 4px !important;
}
.custom-form .jodit-wysiwyg {
  min-height: 301px !important;
}
.dragdrop-container {
  border: 2px dashed #6e19c245;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #e1e4e95c;
  border-radius: 3px;
  min-height: 165px;
  max-height: 165px;

  .all-img {
    min-height: 165px;
    max-height: 165px;
    height: 100%;
    max-width: 100%;
    object-fit: scale-down;

    @media (max-width: 991px) {
      min-height: 100px;
      max-height: initial;
    }
  }
  .every-img {
    min-height: 232px;
    max-height: 232px;
    height: 100%;
    max-width: 100%;
    object-fit: scale-down;

    @media (max-width: 991px) {
      min-height: 100px;
      max-height: initial;
    }
  }

  .drag-n-drop-container {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .drop-area {
      text-align: center;

      img {
        margin-bottom: 20px;
      }
    }
  }
}
